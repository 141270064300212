import React from 'react';

function Banner() {
  return (
    <div className="banner">

    </div>
    );
}

export default Banner;
